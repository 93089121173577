@tailwind base;
@tailwind components;
@tailwind utilities;

.guide-line {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: draw 2s linear forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
